import * as React from "react";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";

const JourneyYocoPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett - Senior Staff Engineer at Yoco"
      metaDescription=""
      chapter={CHAPTERS.YOCO}
    >
      <p>
        <strong>Coming Soon!</strong>
      </p>
    </JourneyPageLayout>
  );
};

export default JourneyYocoPage;
